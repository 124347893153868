import { Box, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC, ReactNode } from 'react'
import { Typography } from 'src/components/atoms/Typography'

interface Props {
  requirements: {
    imageicon: string
    text: string[]
  }
}
interface IGridItem {
  title: string
  paragraph?: string | ReactNode
  urlImage: string
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    margin: 'auto',
    padding: '5px 10px',
    backgroundColor: 'gray',
    display: 'inline-block',
    textAlign: 'center',
    color: theme.palette.common.white,
    fontSize: 'calc(1.5em + 1vw)',
  },
  item: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 80px',
    margin: 'calc(1em + 1vh) 0',
    gap: 10,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '0 25px',
    },
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 10,
    width: '100%',
    flex: 1,
  },
  itemTitle: {
    textAlign: 'left',
    fontSize: 'calc(1.3em + 1vw)',
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
  },
  itemContentContainer: {
    flex: 2,
  },
  itemContent: {
    fontSize: 'calc(.5em + 1vw)',
    marginBottom: 20,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'unset',
    },
  },
}))

const GridItem: FC<IGridItem> = ({ title, paragraph, urlImage }) => {
  const classes = useStyles()
  return (
    <div className={classes.item}>
      <div className={classes.itemHeader}>
        <Avatar src={`${urlImage}`} />
        <Typography variant="h4" className={classes.itemTitle}>{title}</Typography>
      </div>

      <div className={classes.itemContentContainer}>
        <Typography className={classes.itemContent} sx={{ fontSize: 'inherit' }} variant="body1">{paragraph}</Typography>
      </div>
    </div>
  )
}
export const RequirementsSection: FC<Props> = ({ requirements }) => {
  const classes = useStyles()
  const { imageicon, text } = requirements

  const paragraphDevices = 'Debes tener un dispositivo desde donde ingresar al curso y a la plataforma, puedes hacerlo desde un computador, celular o una tablet.'
  const paragraphInternet = 'Para garantizar que puedas tener una experiencia fluida en la plataforma, debes tener una conexión a internet que permita visualizar nuestros cursos sin retrasos o interrupciones.'

  return (
    <Box className={classes.main} component="div">
      <Typography variant="h3" className={classes.title}>Requisitos</Typography>

      <GridItem
        title="Poseer el instrumento"
        urlImage={imageicon}
        paragraph={
          text.map((paragraph, i) => (
            <Box key={i}>
              {paragraph}
            </Box>
          ))
        }
      />

      <GridItem
        title="Dispositivos"
        urlImage="screencourse/icono-multiples-dispositivos.png"
        paragraph={paragraphDevices}
      />

      <GridItem
        title="Internet"
        urlImage="screencourse/icono-wifi.png"
        paragraph={paragraphInternet}
      />
    </Box>
  )
}
