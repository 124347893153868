import { useEffect, useState } from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import LandingTemplate from 'src/components/templates/Landing'
import CourseDetailBanner from 'src/components/CourseDetail/CourseDetailBanner'
import { Benefits } from '../components/Landing/AboutSection'
import { LevelModules } from '../components/Courses/CourseScreen/LevelModules'
import { ParagraphSection } from '../components/Courses/CourseScreen/ParagraphSection'
import { RequirementsSection } from '../components/Courses/CourseScreen/RequirementsSection'
import Page404 from './404'

type Imodulescontent = {
  level: string
  color: string
  text: string
  theme?: string
}
interface IDataCourseScreen {
  path: string
  firstscreenimage: {
    image: string
    title: string
    newstyle: boolean
    textscreen: string
    positiontext: string
    href: string
  },
  modules: {
    levels: string
    name: string
    specialName?: string
    specialDescription?: string
    content: Imodulescontent[]
  },
  learn: string[]
  tips: string[]
  requirements: {
    imageicon: string
    text: string[]
  }
  secondscreenimage: {
    image: string
    title: string
    newstyle: boolean
    subtitle: string
    textscreen: string[]
    positiontext: string
    backgroundPosition?: string
    href: string
  }
}
const DataCourseScreen: IDataCourseScreen[] = [
  {
    path: 'como-tocar-acordeon-vallenato',
    firstscreenimage: {
      image: '/screencourse/curso-de-acordeon-vallenato.jpg',
      title: 'Curso de acordeón vallenato',
      newstyle: false,
      textscreen: 'Aprende a tocar vallenato desde cero. Si ya tienes algún conocimiento, Prueba con un nivel superior.',
      positiontext: 'end',
      href: '/cursos/acordeon',

    },
    modules: {
      levels: '5 Niveles',
      name: 'Principales',
      content: [
        { level: 'B1', color: '#2B78E4', text: 'Primeros pasos Partes del acordeón aire de paseo y sus bajos.' },
        { level: 'B2', color: '#2DE2E2', text: 'Escalas musicales Escalas en el acordeón Primeras técnicas paseo Nv.2' },
        { level: 'M1', color: '#C5D500', text: 'Los acordes Escalas menores ritmo, paseo Nv.3 Merengue Vallenato.' },
        { level: 'M2', color: '#009E0F', text: 'Teoría del diapasón acompañamientos Técnicas Nv.3, Paseo Merengue y Son vallenato.' },
        { level: 'A', color: '#FFA304', text: 'Teoría del diapasón acompañamientos Técnicas Nv.3, Paseo Merengue y Son vallenato.' },
      ],
    },
    learn: ['La metodología de FZ academia, te permite estudiar organizadamente este instrumento musical, evita aprender empíricamente y así no tendrás falencias en el futuro, aprende teoría musical, descubre más acerca de tu acordeón de botones, conoce las técnicas, los ejercicios, las escalas y las canciones que harán que tocar acordeón sea algo divertido, invierte tu tiempo libre en una actividad productiva para ti y para tu familia.'],
    tips: ['El acordeón es uno de los instrumentos más complejos de interpretar, requiere varias acciones independientes, movimiento de los dedos en ambas manos, movimiento del botón del aire, movimiento del brazo izquierdo en el fuelle, y en ocasiones, cantar al mismo tiempo que tocamos, estas actividades exigen total concentración y uso del cerebro, y la mejor forma de dominarlas es mecanizando los movimientos, de esta forma se facilita el trabajo de interpretar canciones.', 'Para aprender a tocar acordeón debes estar motivado(a), enamorarte de este instrumento y ensayar con fecuencia, recomendamos dedicarle entre 20 min - 1 hr al día y totalmente concentrados para que puedas sacar un máximo provecho a cada ensayo.',
    ],
    requirements: {
      imageicon: 'screencourse/icono-acordeon.png',
      text: [
        'Hay distintos tipos de acordeones, el indicado para este curso, es un diatónico de botones, recomendamos un Hohner Rey Vallenato de tonalidad BESAS o un hoffer besas (tenemos una oferta especial, con la compra de un hoffer, te obsequiamos un nivel de nuestro curso, escribenos para mas información, también conocido como 5 letras.', 'Si ya posees un acordeón, y tienes una tonalidad diferente (ADG, GCF, SI MI La, etc.), no te preocupes, puedes tomar el curso sin problemas, ya que las pisadas son las mismas y sin importar el tono que tengas, aunque sonará algo diferente.',
      ],

    },
    secondscreenimage: {
      image: '/screencourse/clases-de-acordeon-vallenato.jpg',
      title: 'Freddy Zuluaga',
      newstyle: true,
      subtitle: 'Tutor del curso',
      textscreen: ['Lo mejor de tocar acordeón es que lo disfrutas tú mismo pero también acompañado'],
      positiontext: 'end',
      href: '/cursos/acordeon',
    },
  },
  {
    path: 'como-tocar-guitarra',
    firstscreenimage: {
      image: '/screencourse/curso-de-guitarra-online.jpg',
      title: 'Curso de Guitarra',
      newstyle: false,
      textscreen: 'Deja de estudiar empírico y sin método, aprende a tocar guitarra de verdad, nuestro curso es interactivo y fácil, conéctate en tus horas favoritas.',
      positiontext: 'start',
      href: '/cursos/guitarra',
    },
    modules: {
      levels: '3 Niveles',
      name: 'Principales',
      content: [
        { level: 'B', color: '#2B78E4', text: 'La guitarra y afinación, escala cromática y escala mayor, acordes mayores en la guitarra, géneros musicales, canciones de practica.' },
        { level: 'M', color: '#009E0F', text: 'La cejilla, tablatura, escalas menores (natural, melódica y armónica), acordes alterados, estructuras armónicas y canciones de práctica.' },
        { level: 'A', color: '#FFA304', text: 'Tonalidad, inversiones, ritmo propio, técnicas para la guitarra, modos musicales, rearmonización de canciones, canciones de práctica nivel avanzado.' },
      ],
    },
    learn: ['La metodología de FZ academia, te permite estudiar organizadamente este instrumento musical, evita aprender empíricamente y así no tendrás falencias en el futuro, aprende teoría musical, descubre más acerca de tu guitarra, conoce las técnicas, los ejercicios, las escalas y las canciones que harán que tocar guitarra sea algo divertido, invierte tu tiempo libre en una actividad productiva para ti y para tu familia.'],
    tips: ['La guitarra es el instrumento musical de cuerdas más conocido en el mundo, y es uno de los que más ha perdurado en la historia, y esto es debido a que es un instrumento melódico pero a la vez rítmico, por eso, lo encontramos en muchos géneros musicales, desde la música cultural, hasta la música comercial.', 'Nuestro curso te muestra un poco de ambos mundos, te enseñamos desde básico hasta avanzado, pero entendiendo a fondo la teoría musical que nos permite movernos a través de la guitarra a nuestro antojo, y así, evitar aprender con tutoriales de YouTube u otros canales con información incompleta y sin metodología.', 'Para aprender nuestro curso, no requieres conocimientos previos de música o de guitarra, puedes hacerlo desde cero, aunque, si ya tienes conocimientos, puedes registrarte y mirar a detalle la temática de cada nivel, y así poder comprar el curso adecuado para ti, ve a tu ritmo, y estudia bien los conceptos teóricos de escalas y acordes, esa es la base de todo.',
    ],
    requirements: {
      imageicon: 'screencourse/icono-guitarra.png',
      text: [
        'Hay distintos tipos de guitarras, guitarra acústica, guitarra eléctrica, guitarra clásica, también las encontramos como las electroacústicas.', 'Todas estas guitarras sirven para aprender, ya que el número de cuerdas son las mismas (seis), tienen la misma afinación, y su estructura del diapasón posee secciones por semitonos (trastes), la diferencia es que algunas tienen cuerdas de acero y otras tienen cuerdas de nylon, pero se aprende por igual.',
      ],

    },
    secondscreenimage: {
      image: '/screencourse/clases-de-guitarra-online.jpg',
      title: 'Fayder Vargas',
      newstyle: true,
      subtitle: 'Tutor del curso',
      textscreen: ['Lo mejor de tocar guitarra es transmitir la música que está en tu interior, pon música a tu vida'],
      positiontext: 'end',
      backgroundPosition: '20%',
      href: '/cursos/guitarra',
    },
  },
  {
    path: 'como-tocar-caja-vallenata',
    firstscreenimage: {
      image: '/screencourse/curso-de-caja-vallenata.jpg',
      title: 'Curso de Caja Vallenata',
      newstyle: false,
      textscreen: 'Domina las técnicas de este instrumento del vallenato con nuestro curso online, disfruta de una parranda con tus amigos y familia tocando la caja.',
      positiontext: 'start',
      href: '/cursos/percusion-vallenata',
    },
    modules: {
      levels: '7',
      name: 'Modulos',
      content: [
        { level: 'Módulo 1', color: '#000', text: 'Lecto escritura Básica de la caja', theme: ' 6 temas' },
        { level: 'Módulo 2', color: '#000', text: 'La caja vallenata en la historia', theme: ' 7 temas' },
        { level: 'Módulo 3', color: '#000', text: 'Las partes de la caja', theme: ' 6 temas' },
        { level: 'Módulo 4', color: '#000', text: 'Ejecución del paseo vallenato', theme: ' 8 temas' },
        { level: 'Módulo 5', color: '#000', text: 'Ejecución del son vallenato', theme: ' 5 temas' },
        { level: 'Módulo 6', color: '#000', text: 'Ejecución del merengue', theme: ' 7 temas' },
        { level: 'Módulo 7', color: '#000', text: 'Ejecución de la puya vallenata', theme: ' 6 temas' },
      ],
    },
    learn: ['La caja vallenata es un instrumento musical en el que se requiere dominar ciertas técnicas o golpes de percusión con la mano, es uno de los instrumentos musicales del vallenato más importantes, hace parte la trilogía como lo llaman los maestros, y en FZ academia, te explicamos de una forma muy sencilla y práctica para que puedas dominar el ritmo base de los 4 aires del vallenato con este instrumento (Paseo, merengue, puya y son), incluyendo el solo de puya, utilizando una metodología de aprendizaje paso a paso propia de la casa, nuestros alumnos nos recomiendan.'],
    tips: ['La caja o el tambor vallenato, es probablemente el instrumento para vallenato más antiguo, por su procedencia africana, y para tocarlo de manera profesional, los cajeros utilizan ambas manos e interpretan todas las técnicas con las mismas, y de esta forma logran tocar los golpes básicos para así, añadirle figuras, adornos, variaciones, repiques y lujos caracteristicos. Para aprender a tocar la caja vallenata, debes estar motivado(a), enamorarte de este instrumento y ensayar con frecuencia, recomendamos dedicarle entre 20 min - 1 hr al día y totalmente concentrado para que puedas sacar un máximo provecho a cada ensayo, las clases del curso de caja vallenata están grabadas en video y organizadas de forma secuencial.',
    ],
    requirements: {
      imageicon: 'screencourse/icono-caja-vallenata.png',
      text: [
        'La caja vallenata profesional puede ser de varios tipos y caracteristicas, segun el tipo de vaso o cuerpo, que puede estar fabricado con madera o con acrilico, tambien se clasifican por el tipo de parche, ya sea de cuero o material sintetico, y por el tipo de aro, que bien puede ser tradicional o aro confort, que es un poco más suavizado para no maltratar las manos del intérprete.', 'Importante revisar el diametro superior del vaso, que debe estar entre los 25 - 30 cm.',
      ],

    },
    secondscreenimage: {
      image: '/screencourse/clases-de-caja-vallenata.jpg',
      title: 'Juan de la Rosa',
      newstyle: true,
      subtitle: 'Tutor del curso',
      textscreen: ['16 años de experiencia en agrupaciones del vallenato: Los diablitos, La desición Vallenata “Erick Escobar”, Diomedes Dionisio Diaz, Miguel Morales, Grupo Kvrass. Con más de 100 Canciones grabadas profesionalmente'],
      positiontext: 'end',
      backgroundPosition: '20%',
      href: '/cursos/percusion-vallenata',
    },
  },
  {
    path: 'como-tocar-guacharaca-vallenata',
    firstscreenimage: {
      image: '/screencourse/curso-de-guacharaca-vallenata.jpg',
      title: 'Curso de Guacharaca Vallenata',
      newstyle: false,
      textscreen: 'La guacharaca es un instrumento musical del vallenato, aprende los rasgados y los 4 aires con nuestra metodología virtual e interactiva.',
      positiontext: 'start',
      href: '/cursos/percusion-vallenata/percusion-vallenata-cursos-de-percusion-vallenata-curso-de-guacharaca',
    },
    modules: {
      levels: '7',
      name: 'Modulos',
      content: [
        { level: 'Módulo 1', color: '#000', text: 'Lecto escritura Básica', theme: ' 6 temas' },
        { level: 'Módulo 2', color: '#000', text: 'La guacharaca en la historia', theme: ' 7 temas' },
        { level: 'Módulo 3', color: '#000', text: 'Las partes de la guacharaca', theme: ' 6 temas' },
        { level: 'Módulo 4', color: '#000', text: 'Ejecución del paseo vallenato', theme: ' 7 temas' },
        { level: 'Módulo 5', color: '#000', text: 'Ejecución del son vallenato', theme: ' 4 temas' },
        { level: 'Módulo 6', color: '#000', text: 'Ejecución del merengue', theme: ' 6 temas' },
        { level: 'Módulo 7', color: '#000', text: 'Ejecución de la puya vallenata', theme: ' 6 temas' },
      ],
    },
    learn: ['La guacharaca vallenata es un instrumento musical de percusión que hace parte del grupo típico del vallenato que también incluye la caja y el acordeón, muchos confundimos el rasgueo de la guitarra con el rasgado de instrumentos como la guacharacara y el guiro, y en FZ academia, te explicamos de una forma muy sencilla y práctica para que puedas dominar el ritmo base de los 4 aires del vallenato (Paseo, merengue, puya y son) con este instrumento, incluyendo el solo de puya, utilizando una metodología de aprendizaje paso a paso propia de la casa, nuestros alumnos nos recomiendan.'],
    tips: ['La guacharaca colombiana, es interpretada por un guacharaquero o guacharaquera y es un instrumento musical colombiano. Para aprender a tocar la la guacharaca, debes estar motivado(a), enamorarte de este instrumento y ensayar con frecuencia, recomendamos dedicarle entre 20 min - 1 hr al día y totalmente concentrado para que puedas sacar un máximo provecho a cada ensayo y así dominar los rasgados técnicos, las clases del curso de guacharaca vallenata están grabadas en video y organizadas de forma secuencial.',
    ],
    requirements: {
      imageicon: 'screencourse/icono-guacharaca.png',
      text: [
        'La guacharaca, se caracteriza por estar hecha de palo o de madera, específicamente de lata o de caña, puede poseer un diametro de 3 cm o menos y una longitud de 40 cm, viene acompañada de un trinche con el que se realiza el rasgado, sobre la superficie corrugada. Se suele confundir con la guira (otro tipo de instrumento de rasgado), también llamada “guacharaca metálica”, que se usa además de la música dominicana, en el vallenato en agrupaciones comerciales y conciertos, con cualquiera de estas dos, puedes aprender con este curso.',
      ],

    },
    secondscreenimage: {
      image: '/screencourse/clases-de-guacharaca-vallenata.jpg',
      title: 'Juan de la Rosa',
      newstyle: true,
      subtitle: 'Tutor del curso',
      textscreen: ['16 años de experiencia en agrupaciones del vallenato: Los diablitos La desición vallenata “Erick Escobar” Diomedes Dionisio Diaz Miguel Morales Grupo Kvrass Con más de 100 Canciones grabadas profesionalmente'],
      positiontext: 'end',
      backgroundPosition: '20%',
      href: '/cursos/percusion-vallenata/percusion-vallenata-cursos-de-percusion-vallenata-curso-de-guacharaca',
    },
  },
  {
    path: 'como-tocar-guitarra-vallenata',
    firstscreenimage: {
      image: '/screencourse/como-tocar-vallenato-en-guitarra.jpg',
      title: 'Curso de Guitarra Vallenata',
      newstyle: false,
      textscreen: 'Aprende a acompañar las canciones del vallenato, domina los rasgueos, los ritmos, aprende armonía con acordes, canciones y punteos.',
      positiontext: 'end',
      href: '/cursos/guitarra/guitarra-curso-de-guitarra-guitarra-vallenata',
    },
    modules: {
      levels: '8',
      name: 'Modulos',
      content: [
        { level: 'Módulo 1', color: '#000', text: 'El vallenato en guitarra', theme: ' 6 temas' },
        { level: 'Módulo 2', color: '#000', text: 'Primeros Rasgueos', theme: ' 6 temas' },
        { level: 'Módulo 3', color: '#000', text: 'Rasgueos clásicos - Paseo y merengue', theme: ' 8 temas' },
        { level: 'Módulo 4', color: '#000', text: 'Rasgueos clásicos - Son y Puya', theme: ' 4 temas' },
        { level: 'Módulo 5', color: '#000', text: 'Rasgueos Modernos', theme: ' 8 temas' },
        { level: 'Módulo 6', color: '#000', text: 'Rasgueos Combinados y ambientes musicales', theme: ' 3 temas' },
        { level: 'Módulo 7', color: '#000', text: 'El punteo vallenato', theme: ' 5 temas' },
        { level: 'Módulo 8', color: '#000', text: 'Canciones avanzadas de práctica', theme: ' 5 temas' },
      ],
    },
    learn: ['FZ academia es una escuela de música online que busca promover la música tradicional a través de la educación, sobrepasando fronteras, por eso nuestros cursos son didácticos y ofrecen herramientas que facilitan el aprendizaje de nuestros estudiantes en más de 20 países. La guitarra vallenata posee características especiales diferentes a otros géneros musicales, tiene patrones rítmicos similares al bolero y una armonía particular, en este curso aprenderás todo sobre el vallenato en guitarra de forma fácil y desde cero, desde su historia, evolución, teoría musical que involucra acordes, escalas y notas, hasta la parte práctica con canciones, conociendo las clases de vallenato en guitarra que existen, paseo vallenato, merengue vallenato, son y puya, así como rasgueos modernos que involucran el vallenato romántico y el nueva ola. El punteo de vallenato en guitarra es esencial para ser un guitarrista integral, estudiando las escalas que podemos tocar, entendiendo su estructura mayor y menor, sabremos como puntear vallenato en guitarra. En nuestra plataforma podrás encontrar el curso grabado en videos tutoriales, material complementario que incluye tablaturas o tabs, contenido escrito y gráfico extra, así como asesoría personalizada con un profesor en algunos de nuestros planes, una metodología propia con la que quedarás atrapado, registrate ahora.'],
    tips: ['Para aprender cómo tocar vallenato en guitarra con nuestro curso virtual, si eres principiante, es importante que tomes el nivel básico e intermedio de la línea principal, para que entiendas la teoría general que se requiere para el curso de guitarra vallenata. Debes estar motivado(a), enamorarte de este instrumento y ensayar con frecuencia, recomendamos dedicarle entre 20 min - 1 hr al día y totalmente concentrado para que puedas sacar un máximo provecho a cada ensayo, las clases del curso de guitarra vallenata están grabadas en video y organizadas de forma secuencial.',
    ],
    requirements: {
      imageicon: 'screencourse/icono-guitarra.png',
      text: [
        'La guitarra para tocar vallenato más recomendada, es una guitarra acústica con cuerdas de nylon, es el tipo de guitarra más usada por tradición y excepcional en las parrandas y serenatas, aunque no es una obligación tener una para aprender, con una guitarra acustica con cuerdas de acero, también se puede aprender e incluso, con una guitarra eléctrica, los tres tipos de guitarra poseen la misma estructura musical en su diapasón y la misma cantidad de cuerdas.',
      ],

    },
    secondscreenimage: {
      image: '/screencourse/clases-de-guitarra-vallenata-online-portada.jpg',
      title: 'Freddy Zuluaga',
      newstyle: true,
      subtitle: 'Tutor del curso',
      textscreen: ['Instrumentista con 25 años de experiencia, y 20 años con la guitarra, docente universitario, productor, arreglista y uno de los fundadores de FZ academia.', '“Yo siempre le comento a mis estudiantes que nunca es tarde para aprender y con motivación siempre se saca el tiempo”'],
      positiontext: 'end',
      backgroundPosition: '20%',
      href: '/cursos/guitarra/guitarra-curso-de-guitarra-guitarra-vallenata',
    },
  },
  {
    path: 'como-tocar-guitarra-urbana',
    firstscreenimage: {
      image: '/screencourse/curso-de-guitarra-urbana.jpg',
      title: 'Curso de Guitarra Urbana',
      newstyle: false,
      textscreen: 'Conoce el género, la influencia de la guitarra y el reggaeton, las fusiones con más géneros, aprende armonía de música urbana, y los rasgueos más importantes.',
      positiontext: 'end',
      href: '/cursos/guitarra/guitarra-curso-de-guitarra-guitarra-urbana',
    },
    modules: {
      levels: '5',
      name: 'Modulos',
      content: [
        { level: 'Módulo 1', color: '#000', text: 'La Esencia de la música urbana', theme: ' 7 temas' },
        { level: 'Módulo 2', color: '#000', text: 'Evolución musical aplicada', theme: ' 7 temas' },
        { level: 'Módulo 3', color: '#000', text: 'La guitarra urbana', theme: ' 5 temas' },
        { level: 'Módulo 4', color: '#000', text: 'Producción de guitarra urbana', theme: ' 5 temas' },
        { level: 'Módulo 5', color: '#000', text: 'Aspectos elementales', theme: ' 4 temas' },
      ],
    },
    learn: ['FZ academia es una escuela de música online que ofrece una forma entretenida y eficiente de estudiar y practicar tu instrumento musical preferido, por eso nuestros cursos son didácticos y ofrecen herramientas que facilitan el aprendizaje de nuestros estudiantes en más de 20 países.', 'La música urbana, es uno de los géneros musicales revolucion de principios del siglo 21, empezando como reggaeton y luego fusionado con el pop, el rock, el soul, jazz, salsa, vallenato, entre otros géneros alrededor del mundo, se globalizó rapidamente, obteniendo galardones en los premios más prestigiosos del arte musical, contando con artistas en una gran cantidad de países, tanto, de habla hispana como en otros idiomas.', 'En este curso aprenderás a tocar reggaeton en guitarra, con un método fácil y organizado, que incluye tabs o tablaturas, acordes, leads, fraseos, ejercicios y demás. Aprenderás sobre la historia del reggaeton y cómo se convirtió el música urbana mediante las fusiones con artistas de distintos géneros musicales. Haremos un análisis de las canciones de reggaeton en guitarra acústica y eléctrica, explicando las variaciones en el ritmo según el tipo de canción, así como para el tipo de guitarra.'],
    tips: ['Para aprender cómo tocar reggaeton en guitarra con nuestro curso virtual, si eres principiante o con muy poco conocimiento de teoría musical, escalas y acordes en la guitarra, es importante que tomes el nivel básico e intermedio de los niveles principales, para que puedas comprender la teoría mínima necesaria que se requiere para el curso de guitarra urbana. Debes estar motivado(a), enamorarte de este instrumento y ensayar con frecuencia, recomendamos dedicarle entre 20 min - 1 hr al día y totalmente concentrado para que puedas sacar un máximo provecho a cada ensayo, las clases del curso de guitarra urbana están grabadas en video y organizadas de forma secuencial.',
    ],
    requirements: {
      imageicon: 'screencourse/icono-guitarra.png',
      text: [
        'La guitarra para tocar reggaeton más recomendada, es cualquier tipo de guitarra, ya sea acústica folk (con cuerdas de acero), acústica clásica (con cuerdas de nylon) o una guitarra eléctrica, ya que con cualquiera de estas se puede tocar cualquier canción de música urbana, hay canciones que requieren de un tipo o del otro para acciones especificas, ya que la música urbana suele llevar más de una guitarra a la vez, pero no es impedimento para aprender y acompañar.',
      ],

    },
    secondscreenimage: {
      image: '/screencourse/clases-de-guitarra-online.jpg',
      title: 'Fayder Vargas',
      newstyle: true,
      subtitle: 'Tutor del curso',
      textscreen: ['Lo mejor de tocar guitarra es transmitir la música que está en tu interior, pon música a tu vida'],
      positiontext: 'end',
      backgroundPosition: '20%',
      href: '/cursos/guitarra/guitarra-curso-de-guitarra-guitarra-urbana',
    },
  },
  {
    path: 'como-tocar-piano',
    firstscreenimage: {
      image: '/screencourse/curso-de-piano.jpg',
      title: 'Curso de  Piano',
      newstyle: false,
      textscreen: 'Aprende piano online para principiantes, medios y avanzados, estudia de manera integral, la teoría y la práctica con géneros musicales latinos.',
      positiontext: 'end',
      href: '/cursos/piano',

    },
    modules: {
      levels: 'Niveles ',
      name: 'Principales',
      specialName: 'Especiales',
      specialDescription: 'Los niveles especiales, son aquellos cursos enfocados en géneros musicales específicos, y son niveles considerados avanzados, para estudiantes que ya han obtenido los conocimientos de los niveles principales, ya tiene el manejo y aplicación de conceptos de armonía y técnicas esenciales en el teclado, explora el curso.',
      content: [
        { level: 'Básico', color: '#2B78E4', text: 'Es el nivel para los principiantes, aprendes los conceptos elementales del piano, las principales escalas mayores y las principales posiciones para tocar' },
        { level: 'Medio', color: '#2DE2E2', text: 'Estudias principales ritmos del piano, nuevas técnicas, las escalas mayores y aprendes a acompañar canciones con ambas manos con acordes alterados.' },
        { level: 'Avanzado', color: '#C5D500', text: 'Aprendes armonización, improvización, los modos musicales,  géneros musicales como el jazz, blues, funk.' },
      ],
    },
    learn: ['En FZ Academia, la mejor escuela de música online, encontrarás el curso ideal para adultos principiantes que sueñan con tocar desde los acordes básicos hasta dominar el piano a nivel experto. Nuestro curso de piano en línea está diseñado para llevarte desde cero hasta un nivel completo, con explicaciones claras, ejercicios prácticos y un enfoque que hace que aprender sea realmente fácil.', 'Además, cuesta poco, con nuestras suscripciones y planes con descuento, tendrás acceso al mejor curso de piano, diseñado para adaptarse a tu ritmo y sin salir de casa. Es la oportunidad perfecta para convertir ese sueño de aprender piano en realidad. ¡Empieza hoy con FZ Academia y transforma tu pasión en música!'],
    tips: ['Para aprovechar al máximo tu aprendizaje de piano con FZ Academia, dedica al menos 15 minutos diarios a practicar de forma constante y enfocada. Sigue el curso en orden para construir una base sólida, prioriza aprender los acordes básicos y combínalos con teoría y práctica. Crea un espacio cómodo para estudiar, graba tus avances para identificar mejoras y no olvides disfrutar del proceso. Sé paciente contigo mismo, celebra tus logros y participa en nuestra comunidad para compartir dudas y motivarte. ¡El progreso está en la constancia y el entusiasmo!',
    ],
    requirements: {
      imageicon: 'screencourse/icono-piano.png',
      text: [
        'Cualquier tipo de teclado es apto para aprender, desde una aplicación en tu teléfono hasta un piano de cola, no existen restricciones, lo importante es que puedas disponer del instrumento musical para practicar cuando lo requieras,  no tiene que ser el teclado mas costoso, cualquiera con al menos 4 octavas funcionará',
      ],

    },
    secondscreenimage: {
      image: '/screencourse/clases-de-piano.jpg',
      title: 'Freddy Zuluaga',
      newstyle: true,
      subtitle: 'Tutor del curso',
      textscreen: ['Instrumentista con 25 años de experiencia, y 16 años con el piano, docente universitario, productor, arreglista y uno de los fundadores de FZ academia.', '“Yo siempre le comento a mis estudiantes que nunca es tarde para aprender y con motivación siempre se saca el tiempo”'],
      positiontext: 'end',
      href: '/cursos/piano',
    },
  },
]

export const CourseScreen: NextPage = () => {
  const [data, setData] = useState<any>({})
  const { push, query } = useRouter()

  const filterCourseScreen = () => {
    const filteredData = DataCourseScreen.find((data) => data.path === query.course)
    if (filteredData) return setData(filteredData)
    if (!filteredData && !DataCourseScreen) return push('/404')
    return null
  }

  useEffect(() => {
    filterCourseScreen()
  }, [query.course])

  const menu = [
    { title: 'Inicio', url: '/' },
    { title: 'Cómo funciona', url: '#about' },
    { title: 'Blog', url: '/blog' },
  ]

  const {
    firstscreenimage,
    modules,
    learn,
    tips,
    requirements,
    secondscreenimage,
  } = data

  if (!Object.keys(data).length) return <Page404 />

  return (
    <LandingTemplate menu={menu}>

      <CourseDetailBanner info={firstscreenimage} />

      <Benefits />

      <LevelModules
        {...{ ...modules, seeCourseUrl: firstscreenimage.href }}
      />

      <ParagraphSection
        learn={learn}
        tips={tips}
      />

      <RequirementsSection
        requirements={requirements}
      />

      <CourseDetailBanner info={secondscreenimage} isSecondaryBanner />

    </LandingTemplate>
  )
}

export default CourseScreen
