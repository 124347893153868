import React, { FC } from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

type StyleProps = {
  background: string
  position: string
  backgroundPosition: string
  isSecondaryBanner: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  home: {
    backgroundImage: ({ background }: StyleProps) => `url('${background}')`,
    backgroundPositionX: ({ position, isSecondaryBanner }: StyleProps) => (position === 'start' && !isSecondaryBanner ? '80%' : '30%'),
    backgroundPosition: ({ isSecondaryBanner, backgroundPosition = '0%' }: StyleProps) => isSecondaryBanner && backgroundPosition,
    color: theme.palette.common.white,
    marginTop: 65,
    height: '100vh',
    width: '100vw',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maxWidth: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: ({ isSecondaryBanner }: StyleProps) => (!isSecondaryBanner ? '0 80px' : '10px 80px'),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: '0 40px !important',
      gridTemplateColumns: ({ isSecondaryBanner }: StyleProps) => isSecondaryBanner && '1fr',
    },
  },
  backdrop: {
    backgroundColor: 'rgba(53, 53, 53, 0.4)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  container: {
    gridColumnEnd: ({ isSecondaryBanner }: StyleProps) => (!isSecondaryBanner ? '1 !important' : 'unset'),
    gridColumn: ({ isSecondaryBanner }: StyleProps) => (isSecondaryBanner ? '2 !important' : 'unset'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: ({ position }: StyleProps) => position,
    textAlign: ({ position }: StyleProps) => (position === 'start' ? 'left' : 'right'),
    zIndex: 1,

  },
  title: {
    fontSize: 'calc(2.5em + 1vw)',
    margin: 0,
    padding: 0,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 'calc(1em + 1vw)',
    margin: 0,
    padding: 0,
    maxWidth: ({ isSecondaryBanner }: StyleProps) => (!isSecondaryBanner ? '50%' : 'unset'),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset !important',
    },
  },
  button: {
    background: theme.palette.primary.main,
    textDecoration: 'none',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: 'calc(1em + 1vw)',
    fontWeight: 'bold',
    marginTop: 30,
    padding: '8px 30px',
    borderRadius: 10,
    cursor: 'pointer',
  },
}
))

interface CourseDetailBannerProps {
  info: {
    path: 'como-tocar-guitarra-vallenata',
    image: string,
    title: string,
    newstyle: boolean,
    textscreen: string[],
    positiontext: string,
    backgroundPosition: string,
    href: string,
  },
  isSecondaryBanner?: boolean
}

const CourseDetailBanner: FC<CourseDetailBannerProps> = ({ info, isSecondaryBanner }) => {
  const classes = useStyles({ background: info.image, position: info.positiontext, isSecondaryBanner, backgroundPosition: info.backgroundPosition })
  return (
    <section id="home" className={classes.home}>
      <div className={classes.container}>
        <h1 className={classes.title}>{info.title}</h1>

        {Array.isArray(info.textscreen) ? info.textscreen.map((text) => (
          <>
            <p className={classes.subtitle}>{text}</p>
            <br />
          </>
        )) : <p className={classes.subtitle}>{info.textscreen}</p>}
        <a href={info.href} className={classes.button}>Ver curso</a>
      </div>
      <div className={classes.backdrop} />
    </section>
  )
}

export default CourseDetailBanner
