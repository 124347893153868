import Error from 'src/components/atoms/Error'
import { redirectTo } from 'src/utils/redirectTo'
import Link from 'next/link'
import { Button } from 'src/components/atoms/Button'
import { getUser } from 'src/services/Auth'
import { isServer } from 'src/utils/isServer'

const Page404 = () => {
  let role = ''
  const user = !isServer ? getUser() : null
  if (!!user && !!user.role) role = user.role
  const route = redirectTo(role)

  return (
    <Error
      message="Lo sentimos esta pagina no ha sido encontrada."
      title="Pagina no encontrada"
      image="/images/errors/404.png"
    >
      <Link href={route} passHref>
        <Button variant="contained" color="primary">
          Volver al inicio
        </Button>
      </Link>
    </Error>
  )
}

export default Page404
